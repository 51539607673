
import React, { useEffect,useState } from "react";
import { Table, Button, Card, Row, Col, ListGroup } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { ethers } from "ethers"
import { cliqueMiner, unixToDate, linkAddress } from '../class/Tools'

const LatestBlocks = ({items,provider}) => {

    const [miners, setMiners] = useState([]);

    useEffect(() => {
        const fetchMiners = async () => {
            const miners = await Promise.all(
                items.map(async (item) => {
                    const block = await provider.send('eth_getBlockByNumber', [ethers.utils.hexValue(item.number), true]);
                    const miner = cliqueMiner(block);
                    return miner;
                })
            );
            setMiners(miners);
        };
        console.log(items)

        fetchMiners();
    }, [items, provider]);
    console.log(items)
    return (
        <Card classMap="shadowCard">
            <Card.Header className="std-card-title"></Card.Header>
            <Card.Body className="std-card-info">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Block</th>
                            <th>Timestamp</th>
                            <th>tx</th>
                            <th>Gas Used</th>
                            <th>Gas Limit</th>
                            <th>Difficulty</th>
                            <th>Miner/Signer</th>
                        </tr>
                    </thead>
                    <tbody>
                    {items.map((item, idx) => (
                        <tr key={idx}>
                            <td><Link to={`/block/${item.number}`}>{item.number}</Link></td>
                            <td>{unixToDate(item.timestamp)}</td>
                            <td>{item.transactions.length}</td>
                            <td>{parseInt(item.gasUsed)} gwei</td>
                            <td>{parseInt(item.gasLimit)} gwei</td>
                            <td>{item.difficulty}</td>
                            <td><Link to={`/address/${miners[idx]}`}>{miners[idx]}</Link></td>
                        </tr>
                    ))}
                    </tbody>
            </table>
        </Card.Body>
        </Card>
    );
};
export default LatestBlocks;