import Config from '../config.json'
import { useState, useEffect } from 'react'
import { ethers } from "ethers"
import { useParams } from 'react-router-dom'
import { Row, Col, Spinner, Button } from 'react-bootstrap'

import TransactionList from '../components/TransactionList'

//const axios = require('axios').default;

const Txs = ({ networkName }) => {
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [blockContent, setTxsContent] = useState([])
    const [blockNumber, setBlockNumber] = useState(parseInt(params.blockNumber))
    const [currentPage, setCurrentPage] = useState(0)
    const [maxPage, setMaxPage] = useState(0)

    //subscribe to new blocks with ethers.js
    const getLatestTransactions = async () => {
        let provider = new ethers.providers.JsonRpcProvider(Config.node);
        const blockNumber = await provider.getBlockNumber()
        const txsPerPage = 25;
        const totalPages = Math.ceil(blockNumber / txsPerPage);
        setMaxPage(totalPages - 1); 

        const startBlock = blockNumber - (currentPage * txsPerPage);
        const endBlock = startBlock - txsPerPage + 1;

        const newItems = [];
        let txs = []
        
        for (let i = startBlock; i >= endBlock && i > 0; i--) {
            const block = await provider.getBlock(i)
            newItems.push(block);
            block.transactions.forEach(async (tx) => {
                const txData = await provider.getTransaction(tx)
                txData.method = txData.data.slice(0, 10)
                txs.push(txData)
            })
        }
        console.log(txs)
        setTxsContent(txs)
        setLoading(false);
    }

    // Change page function
    const changePage = (direction) => {
        if (direction === 'next' && currentPage < maxPage) {
            setCurrentPage(currentPage + 1);
        } else if (direction === 'previous' && currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    }

    // Use Effect for the launch of the function whenever the variable currentPage changes
    useEffect(() => {
        getLatestTransactions();
    }, [currentPage]);

      if (loading) return (
        <main style={{ padding: "1rem 0" }} className='app-body'>
          <h4 className='Title'>Transactions</h4>
          Loading the list of all transactions in block order
          <Spinner animation="border" style={{ display: 'flex' }} />
        </main>
      )

      // Render ---------------------------------------------------------------------------------------------------------- //
      return (
        <main style={{ padding: "1rem 0" }} className='app-body'>
            <h4 className='Title'>Transactions</h4>
            List of all transactions in block order
            <TransactionList txs={blockContent} />
            <Row>
                    <Col xs={6} md={6} lg={6} xl={6}>
                        <Button onClick={() => changePage('previous')} disabled={currentPage === 0}> Next Transactions</Button>
                    </Col>
                    <Col xs={6} md={6} lg={6} xl={6} className="text-right">
                        <Button onClick={() => changePage('next')} disabled={currentPage === maxPage}> Previous Transactions</Button>
                    </Col>
            </Row>
        </main>
    );
}
export default Txs