import Config from '../config.json'
import { useState, useEffect } from 'react'
import { ethers } from "ethers"
import { Row, Col, Spinner, Button } from 'react-bootstrap'

import LatestBlocksExtented from '../components/LatestBlocksExtented'

const Blocks = ({ networkName }) => {
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [maxPage, setMaxPage] = useState(0)
    const [settedProvider,setProvider] =useState()

    //subscribe to new blocks with ethers.js
    const getLatestBlocks = async () => {
        let provider = new ethers.providers.JsonRpcProvider(Config.node);
        setProvider(provider)
        const blockNumber = await provider.getBlockNumber()
        const blocksPerPage = 25;
        const totalPages = Math.ceil(blockNumber / blocksPerPage);
        setMaxPage(totalPages - 1); 

        const startBlock = blockNumber - (currentPage * blocksPerPage);
        const endBlock = startBlock - blocksPerPage + 1;

        const newItems = [];

        for (let i = startBlock; i >= endBlock && i > 0; i--) {
            const block = await provider.getBlock(i)
            newItems.push(block);
        }

        setItems(newItems);
        setLoading(false);
    }

    // Change page function
    const changePage = (direction) => {
        if (direction === 'next' && currentPage < maxPage) {
            setCurrentPage(currentPage + 1);
        } else if (direction === 'previous' && currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    }

    // Use Effect for the launch of the function whenever the variable currentPage changes
    useEffect(() => {
        getLatestBlocks();
    }, [currentPage]);

    // Render of the component //
    if (loading) {
        return (
            <main style={{ padding: "1rem 0" }} className='app-body'>
                <h4 className="Title">Latest Blocks</h4>
                Loading... <br/><Spinner animation="border" variant="secondary" />
            </main>
        )
    } else {
        return (
            <main style={{ padding: "1rem 0" }} className='app-body'>
                <h4 className="Title">Latest Blocks</h4>
                <Row >
                    <Col xs={12} md={12} lg={12} xl={12}>
                        <LatestBlocksExtented items={items} provider={settedProvider} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={6} lg={6} xl={6}>
                        <Button onClick={() => changePage('previous')} disabled={currentPage === 0}> Next Blocks</Button>
                    </Col>
                    <Col xs={6} md={6} lg={6} xl={6} className="text-right">
                        <Button onClick={() => changePage('next')} disabled={currentPage === maxPage}> Previous Blocks</Button>
                    </Col>
                </Row>
            </main>
        );
    }
}
export default Blocks