import Config from '../config.json'
import { cliqueMiner, copyToClipboardButton } from '../class/Tools'
import { getProvider, isContract } from '../class/Evm'

// -=< Ethers >=------------------------------------------------------------------------------------------------------------ //
import { ethers } from "ethers"

// -=< React.Component >=- ------------------------------------------------------------------------------------------------- //
import { useState, useEffect } from 'react'
import { Card, Nav, Row, Col, Spinner,Button} from 'react-bootstrap'
import { useParams } from "react-router-dom"

// -=< Components >=- ------------------------------------------------------------------------------------------------------ //
import AddressOverview from '../components/AddressOverview'
import AddressMoreInfo from '../components/AddressMoreInfo'
import AddressTxTable from '../components/AddressTxTable'
import ContractOverview from '../components/ContractOverview'
import ContractMoreInfo from '../components/ContractMoreInfo'

const axios = require('axios').default;

// ---------------------------------------------------------------------------------------------------------------------------- //
const Address = ({ networkName }) => {

    // -=< Variables >=- ------------------------------------------------------------------------------------------------------ //

    const [txs, setTxs] = useState([])

    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(false)
    const [address, setAddress] = useState({
        address: params.address,
        balance: 0,
        value: 0.00
    })
    const [contract, setContract] = useState(false)
    const [activeTab, setActiveTab] = useState('#tx')
    const [cardbody, setCardbody] = useState('')

    const [blockContent, setTxsContent] = useState([])
    const [blockNumber, setBlockNumber] = useState(parseInt(params.blockNumber))
    const [currentPage, setCurrentPage] = useState(0)
    const [maxPage, setMaxPage] = useState(0)


    /*
    // -=< Functions >=- ------------------------------------------------------------------------------------------------------ //
    const get_account_txlist1 = async (addr) => {

        const apicall = Config.restAPI + '/api?module=account&action=txlist&address=' + addr + '&startblock=0&endblock=999999999&sort=asc&apikey=' + Config.ApiKeyToken
        const response = await axios.get(apicall)
        .then(function (response) {
          setTxs(response.data.result)
        })
        .catch(function (error) {
         // handle error
          console.log(error);
        })
       .then(function () {
          // always executed
        });

    }

    */

    // Change page function
    const changePage = (direction) => {
        if (direction === 'next' && currentPage < maxPage) {
            setCurrentPage(currentPage + 1);
        } else if (direction === 'previous' && currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    }

    const get_account_txlist = async (addr) => {

        let provider = new ethers.providers.JsonRpcProvider(Config.node);

        /*verify if metamask is connected
        if (window.ethereum) {
            provider = new ethers.providers.Web3Provider(window.ethereum);
        }
        */
        const blockNumber = await provider.getBlockNumber()
        const txsPerPage = 25;
        const totalPages = Math.ceil(blockNumber / txsPerPage);
        setMaxPage(totalPages - 1); 

        const startBlock = blockNumber - (currentPage * txsPerPage);
        const endBlock = startBlock - txsPerPage + 1;

        const newItems = [];
        let txs = []

        console.log("---------ADDR----------")
        console.log(addr)
        setLoading2(true)
        for (let i = startBlock; i >= endBlock && i > 0; i--) {
            const block= await provider.getBlock(i)
            const parsedBlock = await provider.send('eth_getBlockByNumber', [ethers.utils.hexValue(block.number), true])
            const signer= cliqueMiner(parsedBlock)
            newItems.push(block);
            if(signer===addr){
                block.transactions.forEach(async (tx) => {
                    const txData = await provider.getTransaction(tx)
                    txData.method = txData.data.slice(0, 10)
                    txs.push(txData)
                    console.log(txData)
                })
            } else{
                continue;
            }
        }
        console.log(txs)
        setTxsContent(txs)
        setLoading(false);
        setLoading2(false)
        
    }
    // ---------------------------------------------------------------------------------------------------------------------------- //
    const getAddressTokenList = async (addr) => {

    }

    // ---------------------------------------------------------------------------------------------------------------------------- //
    const getOnChainAddressInfo = async (_wallet) => {

        let provider = new ethers.providers.JsonRpcProvider(Config.node);

        /*verify if metamask is connected
        if (window.ethereum) {
            provider = new ethers.providers.Web3Provider(window.ethereum);
        }
        */

        const _balance = await provider.getBalance(_wallet)

        //get account balance in ether
        const balance = ethers.utils.formatEther(_balance)

        const transactions = await provider.getTransactionCount(_wallet)

        //get transactions for address
        //const transaction = await provider.getTransaction(params.walletAddress)
        //console.log(transaction)


        setAddress({
            address: _wallet,
            balance: balance,
            value: 0.00
        })

        if (await isContract(_wallet) === true) {
            setContract(true)
        }

        //getAddressTokenList(_wallet)
        setLoading(false)
    }

    const getAccountInfo = async () => {

        //get account info from the API
        const apicall = Config.restAPI + '/api?module=account&action=balance&address=' + params.address + '&tag=latest&apikey=' + Config.ApiKeyToken
        const response = await axios.get(apicall)
        .then(function (response) {
            setAddress(response.data.result)
        }
        )
    }

    // -=< Effects >=- ------------------------------------------------------------------------------------------------------ //
    useEffect(() => {
        let timer = setTimeout(() => {
            console.log(blockContent)
            getOnChainAddressInfo(params.walletAddress)
            //getAccountInfo()

            // Transactions Tab //
            if (activeTab === '#tx')  {
                const c = (
                    <AddressTxTable txs={blockContent} walletAddress={params.walletAddress}/>
                )
                setCardbody(c)
                
            } else if (activeTab === '#inttx') {
                const c = (
                    <AddressOverview address={address}/>
                )
                setCardbody(c)
            }
            //console.log(params.walletAddress,address.address)

            console.log(cardbody)
        }, 1000);

        return () => clearTimeout(timer)
    }, [activeTab, params.walletAddress, address, txs, networkName,currentPage])

     // Use Effect for the launch of the function whenever the variable currentPage changes
     useEffect(() => {
        get_account_txlist(params.walletAddress);
    }, [currentPage]);

    //if params changes, reload page

    if (loading) return (
        <main style={{ padding: "1rem 0" }} className='app-body'>
            <h4 className="Title">Address {params.walletAddress}{copyToClipboardButton(params.walletAddress)}</h4>
            <Spinner animation="border" variant="primary" />
        </main>
    )



    // -=< Render >=- ------------------------------------------------------------------------------------------------------ //
    return (
        <main style={{ padding: "1rem 0" }} className='app-body'>

                <h4 className="Title">Address {params.walletAddress}{copyToClipboardButton(params.walletAddress)}</h4>

                <Row className="justify-content-center">
                    <Col xs={12} md={12} lg={6}>
                        {contract ? (
                            <ContractOverview address={address} />
                        ) : (
                            <AddressOverview address={address} />
                        )}
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                    {contract ? /* (
                        <ContractMoreInfo address={address} />
                    ) */null : (
                        <AddressMoreInfo address={address} />
                    )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <Card className="infobox">
                            <Card.Header>
                                <Card.Title><b>Transactions List</b></Card.Title>
                                <Nav variant="tabs" defaultActiveKey="#tx" onSelect={(selectedKey) => setActiveTab(selectedKey)} >
                                    <Nav.Item>
                                        <Nav.Link href="#tx">Transactions</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="#inttx">Internal Tx</Nav.Link>
                                    </Nav.Item>
                                    {/*
                                    <Nav.Item>
                                        <Nav.Link href="#int">Contracts</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="#disabled" disabled>
                                        Disabled
                                        </Nav.Link>
                    </Nav.Item>*/}
                                </Nav>
                            </Card.Header>
                            <Card.Body>
                                {activeTab === '#tx'? <div style={{textAlign:"center"}}>List of all transactions by an address in block order</div>: null}
                                {activeTab === '#inttx'? <div style={{textAlign:"center"}}>Balance of the address and other</div>: null}
                                
                                {cardbody}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {loading2? 
                    <Spinner animation="border" variant="primary" style={{padding:"5px"}}/> : null   
                }
                <Row>
                    <Col xs={6} md={6} lg={6} xl={6}>
                        <Button onClick={() => changePage('previous')} disabled={currentPage === 0}> Next Transactions</Button>
                    </Col>
                    <Col xs={6} md={6} lg={6} xl={6} className="text-right">
                        <Button onClick={() => changePage('next')} disabled={currentPage === maxPage}> Previous Transactions</Button>
                    </Col>
             </Row>
            </main>
    );
}
export default Address